import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext } from '../../../context/globalContext';
import logoWM from '../../../assets/images/logoWM.png';
import logoGP from '../../../assets/images/logo_grupo_platino.png';

export const Footer = () => {
	const { companyInfo } = React.useContext(GlobalContext);

	return (
		<>
			<footer className='site-footer style-3' id='footer'>
				<div className='mx-3 mx-md-5'>
					<div className='mx-md-2'>
						<div className='footer-row'>
							<div
								className='aos-item mb-3'
								data-aos='fade-up'
								data-aos-duration='800'
								data-aos-delay='200'
							>
								<div className='widget_about'>
									<div className='footer-logo logo-white'>
										<Link to={'./'}>
											<img
												src={logoWM}
												alt='Logo William y Molina'
											/>
										</Link>
									</div>
									<p className='text-light' style={{ marginTop: '-46px' }}>
										Ser una empresa líder enfocada en brindar los mejores
										servicios de construcción a nuestros clientes, manteniendo
										nuestro enfoque fijo en los valores y principios de la
										empresa.
									</p>
								</div>
							</div>
							<div
								className='aos-item footer-contact'
								data-aos='fade-up'
								data-aos-duration='800'
								data-aos-delay='300'
							>

								<div className='widget_getintuch d-grid gap-4 mb-4'>
									<ul>
										<li>
											<i className='flaticon-placeholder'></i>
											<span className='text-light'>
											{companyInfo.address}
											</span>
										</li>
										<li>
											<i className='flaticon-call'></i>
											<span>
												<a href={`tel:+504 ${companyInfo.cellPhone}`}>+504 {companyInfo.cellPhone}</a>
											</span>
										</li>
										<li>
											<i className='flaticon-chat-1'></i>
											<span>
												<a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
											</span>
										</li>
									</ul>
								</div>
								<div
									className='aos-item'
									data-aos='fade-up'
									data-aos-duration='800'
									data-aos-delay='300'
								>
									<div style={{ maxWidth: '200px' }}>
										<LazyLoadImage effect='opacity' delayTime={300} src={logoGP} alt='Grupo Platino Logo' />
									</div>
									<div className='text-md-start'>
										<p className='pt-4 pt-md-5'>
											Copyright © 2023{'	'}
											Platino Software
										</p>
										<p>Todos los derechos reservados.</p>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
