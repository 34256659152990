import { useLayoutEffect } from 'react';

export const useLockBodyScroll = (modalIsOpen) => {
	useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
		if (modalIsOpen) {

      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
    }

		// Re-enable scrolling when component unmounts
		return () => (document.body.style.overflow = originalStyle);
	}, [modalIsOpen]);
};
