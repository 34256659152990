import React, { useEffect, useContext } from 'react';
// import 'react-lazy-load-image-component/src/effects/blur.css';
import "./assets/css/style.css";

import Router from './pages/Router';

// components
import { LoadingModal } from './shared/loading-modal';
// hooks
import { useFirstPageLoad } from "./hooks/use-first-load";
import { useThemeDetector } from './hooks/use-theme-detector';

// services
import { CompaniesService } from './services/company-service';
import { ProjectService } from './services/project-service';

// context
import { GlobalContext } from './context/globalContext';

function App() {
	const { companyInfo, setCompanyInfo, projects, setProjects } = useContext(GlobalContext);
	const isLoadingPage = useFirstPageLoad();
	const isDarkTheme = useThemeDetector();
	document.body.classList.add(isDarkTheme ? 'layout-dark' : 'layout-light');

	// check if body has class layout-dark
	const hasDarkTheme = document.body.classList.contains('layout-dark');
	if (hasDarkTheme) {
		document.body.classList.remove('layout-light');
	}

/* When the page component is mounted, call the apis to get all the page info at once. */
	useEffect(() => {
		Promise.all([CompaniesService.getCompanyInfo(), ProjectService.getAllProjects()])
		.then(([companyInfo, projects]) => {
			setCompanyInfo(companyInfo);
			setProjects(projects);
		}).catch(error => console.log(error));
	}, [setCompanyInfo, setProjects]);

	if (isLoadingPage || !companyInfo || !projects) {
		return <LoadingModal />;
	}

	return (
		<div className="App">
				<Router />
		</div>
	);
}

export default App;
