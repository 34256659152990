import { useState, createContext } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [projects, setProjects] = useState(null);

  return (
    <GlobalContext.Provider value={{ companyInfo, setCompanyInfo, projects, setProjects }}>
      {children}
    </GlobalContext.Provider>
  );
}