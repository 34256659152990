import React from 'react';
// hooks
import { useMediaQuery } from '../../hooks/use-media-query';

//components
import { MainAboutUsContent } from './main-content/index';
import { ExpertsSection } from './experts-section/index';
import { PageTitle } from '../../shared/page-title/index';

//images
import { CertificateComponent } from '../../shared/certificate-component/index';
import { MetricsSection } from './metrics-section/index';
import heroImage from '../../assets/images/team/team8.jpg';
import { Helmet } from 'react-helmet';

export const AboutUsPage = () => {
	const isTablet = useMediaQuery('(max-width: 991px)');

	return (
		<>
			<Helmet>
				<title>W&M - Sobre Nosostros</title>
				<meta name="description"
					content="Somos una empresa lideres en la construcción de obras de infraestructura, comprometida con la satisfacción de nuestros clientes por medio del cumplimiento de sus requisitos, legales y reglamentarios aplicables, así como los compromisos adquiridos con las partes interesadas pertinentes." />
				<meta name='keywords' content='Construcción, infraestructura vial, constructora william y Molina, constructoras en sps, san pedro sula, constructoras en honduras' />
			</Helmet>
			<div className='page-content bg-white'>
				<PageTitle
					title='Sobre Nosotros'
					parentPage='Inicio'
					childPage=''
					heroImage={heroImage}
				/>
				<section
					className='section about-content-section px-md-5'
				>
					<div className={`px-4 ${!isTablet && 'container-fluid'}`}>
						<MainAboutUsContent />
					</div>
				</section>
				<CertificateComponent />
				<ExpertsSection />
				<MetricsSection />
			</div>
		</>
	);
}
