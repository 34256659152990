import { useState, useEffect } from 'react';

/**
 * It returns a boolean value that indicates whether the user's OS is set to dark mode or not
 */
export const useThemeDetector = () => {
	const getCurrentTheme = () =>
		window.matchMedia('(prefers-color-scheme: dark)').matches;

	const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());

	const mqListener = (e) => setIsDarkTheme(e.matches);

	useEffect(() => {
		const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
		darkThemeMq.addListener(mqListener);
		return () => darkThemeMq.removeListener(mqListener);
	}, []);
	return isDarkTheme;
};
