import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//Layouts
import { PageTitle } from '../../shared/page-title';

// context
import { GlobalContext } from '../../context/globalContext';

function ProjectDetails() {
	const { projects } = useContext(GlobalContext);
	const { id } = useParams();
	const { openLightbox } = useLightbox();
	const options = {
		settings: {
			slideAnimationType: 'slide',
		},
	};

	const projectDetails = projects.find(
		(project) => project.projectId === parseInt(id)
	);

	const {
		name,
		year,
		status,
		category,
		description,
		images,
		address,
		paragraphs,
	} = projectDetails;
	const { name: statusName } = status;
	const image1 = images[0];
	const filteredImages = images.slice(1);
	const fullImgClass = 'col-12';
	const halfImgClass = 'col-sm-6';

	const iconBoxWraper = [
		{ icon: 'fas fa-map-marker-alt', title: 'UBICACIÓN', subtitle: address },
		{ icon: 'fas fa-tape', title: 'ESTADO', subtitle: statusName },
		{ icon: 'fas fa-synagogue', title: 'CATEGORÍA', subtitle: category.name },
	];

	return (
		<>
			<div className='page-content bg-white'>
				<PageTitle
					title='Detalles del proyecto'
					parentPage='inicio'
					childPage='Proyectos'
					heroImage={image1.url}
				/>
				<section className='section-full content-inner-2 port-detail1 px-md-5'>
					<div className='px-4 container-fluid px-md-4'>
						<div className='row align-items-center'>
							<div className='col-lg-6 col-md-12'>
								<div className='section-head'>
									<h3 className='title'>{name}</h3>
								</div>
							</div>
							<div className='col-lg-6 col-md-12 m-b30'>
								<div className='dz-content'>
									<p>{description}</p>
								</div>
							</div>
						</div>
						<div className='row'>
							{paragraphs.map((data, ind) => (
								<div className='col-md-6' key={ind}>
									<div className='dz-content m-b30'>
										<p>{data.section}</p>
									</div>
								</div>
							))}
						</div>
						<div className='row justify-content-center m-b15'>
							{iconBoxWraper.map((data, ind) => (
								<div className='col-xl col-md-4 col-sm-6' key={ind}>
									<div className='icon-bx-wraper left style-8 m-b30'>
										<div className='icon-bx-sm radius'>
											<span className='icon-cell text-dark'>
												<i className={data.icon}></i>
											</span>
										</div>
										<div className='icon-content'>
											<h5 className='dz-title m-b0'>{data.title}</h5>
											<p>{data.subtitle}</p>
										</div>
									</div>
								</div>
							))}
						</div>
						<SRLWrapper options={options}>
							<div className='row m-b30 lightgallery'>
								<div className={`m-sm-b15 m-b30 ${fullImgClass}`}>
									<div className='dz-box style-4'>
										<div className='dz-media height-md'>
											<LazyLoadImage
												width={'100%'}
												height={'100%'}
												effect='opacity'
												src={image1.url}
												alt='Proyectos William y Molina'
											/>
										</div>
										<span
											data-exthumbimage={image1.url}
											data-src={image1.url}
											className='view-btn lightimg'
											title=''
											onClick={() => openLightbox(0)}
										>
											<i className='fas fa-plus'></i>
										</span>
									</div>
								</div>
								{filteredImages.map((image, index) => (
									index <= 2 ?
									<div className={`m-sm-b15 ${halfImgClass}`} key={image.imageId}>
										<div className='dz-box style-4'>
											<div className='dz-media height-md'>
												<LazyLoadImage
													width={'100%'}
													height={'100%'}
													effect='opacity'
													src={image.url}
													alt='Proyectos William y Molina'
												/>
											</div>
											<span
												data-exthumbimage={image.url}
												data-src={image.url}
												className='view-btn lightimg'
												title=''
												onClick={() => openLightbox(index + 1)}
											>
												<i className='fas fa-plus'></i>
											</span>
										</div>
									</div>
									: <></>
								))}
							</div>

						</SRLWrapper>
					</div>
				</section>
			</div>
		</>
	);
}
export { ProjectDetails };
