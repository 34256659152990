import React from 'react';
// import WOW from 'wowjs';

//Components
import { HeroHome } from './hero-home';
import { CertificateComponent } from '../../shared/certificate-component';
import { AboutUs } from './about-us';
import { CustomersSlider } from '../../shared/customers-slider';
import { Contact } from './contact';
import { ProjectsSlider } from './projects-slider';
import { OurServices } from './our-services';

export function HomePage() {
	return (
		<>
			<div className='page-content bg-white'>
				<HeroHome />
				<AboutUs />
				<div className='projects-wrapper pb-md-5'>
					<ProjectsSlider />
				</div>
				<div className='certification-wrapper'>
					<CertificateComponent />
				</div>
				<div className='services-wrapper'>
					<OurServices />
				</div>
				<CustomersSlider />
				<Contact />
			</div>
		</>
	);
};
