import { useRef } from 'react';
import CountUp from 'react-countup';

// hooks
import { useMediaQuery } from '../../../hooks/use-media-query';
import { useIntersectionObserver } from '../../../hooks/use-intersection-observer';

// data
const counterSection = [
	{ title: 'm² de construcción vertical', number: '150000' },
	{
		title: 'millones m² de urbanización',
		number: '1.5',
		decimal: true,
	},
	{ title: 'km de red vial', number: '500' },
	{
		title: 'metros lineales de sistemas de conducción de agua instalada',
		number: '50000',
	},
	{ title: 'metros lineales de construcción de puentes', number: '1500' },
	{ title: 'viviendas construidas', number: '2500' },
];

export const MetricsSection = () => {
	const isMobile = useMediaQuery('(max-width: 767px)');

	const ref = useRef(null);
	const entry = useIntersectionObserver(ref, {
		freezeOnceVisible: true,
	});
	const isVisible = !!entry?.isIntersecting;

	return (
		<section className='content-inner-1 bg-white px-4 container-fluid px-md-5'>
			<div className='section-head style-1 px-md-4 text-center'>
				<h6 className='sub-title text-primary'>Logros y Desafíos</h6>
				<h2 className='title m-b20'>Nuestros logros</h2>
			</div>
			<div
				className='gap-5 mt-5 justify-content-lg-center m-auto'
				style={{
					display: 'grid',
					gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr',
				}}
				ref={ref}
			>
				{counterSection.map((data, ind) => (
					<div
						className='counter-style-1 aos-item d-flex flex-column align-items-center text-center'
						data-aos='fade-up'
						data-aos-duration='800'
						data-aos-delay='300'
						key={ind}
					>
						<div className='counter-num text-primary'>
							<small>+</small>
							<h2 className='counter text-primary'>
								{isVisible && (
									<CountUp
										end={data.number}
										duration={3}
										decimals={data.decimal ? '1' : '0'}
										separator=','
										decimal='.'
									/>
								)}
							</h2>
						</div>
						<h5 className='counter-text m-auto'>{data.title}</h5>
					</div>
				))}
			</div>
		</section>
	);
};
