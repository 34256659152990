import { Link } from 'react-router-dom';
// data
import React from 'react';
import { GlobalContext } from '../../../context/globalContext';

export const Contact = () => {
	const { companyInfo } = React.useContext(GlobalContext);
	return (
		<section className='content-inner'>
			<div className='mx-4 mx-md-5'>
				<div className='row align-items-center mx-md-2'>
					<div className='col-lg-6 m-b30'>
						<div
							className='section-head style-1 aos-item'
							data-aos='fade-right'
							data-aos-duration='800'
							data-aos-delay='300'
						>
							<h5 className='sub-title text-primary'>CONTÁCTANOS</h5>
							<h2 className='title m-b20'>Ponerse en contacto</h2>
							<p>
								¿Tienes alguna duda o comentario sobre nuestra compañia? Utilice los siguientes medios de comuicación para dejarnos saber tus opiniones.
							</p>
						</div>
						<div
							className='icon-bx-wraper left style-2 m-b10 aos-item wow fadeInRight'
							data-wow-delay='0.01s'
							data-wow-duration='1.3s'
						>
							<div className='icon-bx-sm radius bg-primary'>
								<Link to={'#'} className='icon-cell text-white'>
									<i className='far fa-envelope'></i>
								</Link>
							</div>
							<div className='row align-items-center w-100'>
								<div className='col-sm-6'>
									<h4 className='dz-title mb-sm-0 mb-2'>Correo - electrónico</h4>
								</div>
								<div className='col-sm-6'>
									<h5>
										<a href={`mailto:${companyInfo.email}`} className='m-b0'>
											{companyInfo.email}
										</a>
									</h5>
								</div>
							</div>
						</div>
						<div
							className='icon-bx-wraper left style-2 m-b10 aos-item wow fadeInRight'
							data-wow-delay='0.01s'
							data-wow-duration='1.3s'
						>
							<div className='icon-bx-sm radius bg-primary'>
								<Link to={'#'} className='icon-cell text-white'>
									<i className='fas fa-phone'></i>
								</Link>
							</div>
							<div className='row align-items-center w-100'>
								<div className='col-sm-6'>
									<h4 className='dz-title mb-sm-0 mb-2'>Numero de teléfono</h4>
								</div>
								<div className='col-sm-6'>
									<h5>
										<a href={`tel:+504 ${companyInfo.cellPhone}`} className='m-b0 text-nowrap'>
											+504 {companyInfo.cellPhone}
										</a>
									</h5>
								</div>
							</div>
						</div>
						<div
							className='icon-bx-wraper left style-2 m-b10 aos-item wow fadeInRight'
							data-wow-delay='0.01s'
							data-wow-duration='1.3s'
						>
							<div className='icon-bx-sm radius bg-primary'>
								<Link to={'#'} className='icon-cell text-white'>
									<i className='fas fa-map-marker-alt'></i>
								</Link>
							</div>
							<div className='row align-items-center w-100'>
								<div className='col-sm-3'>
									<h4 className='dz-title mb-sm-0 mb-2'>Ubicación</h4>
								</div>
								<div className='col-sm-9'>
									<h5 className='m-b0'>
										{companyInfo.address}
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div className='col-lg-6'>
						<div className='map-iframe'>
							<iframe
								title='myFrame'
								src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3844.7277407054503!2d-87.98799288545983!3d15.499070089235323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f66449bbe9fe2c3%3A0x9e812d040648c6d2!2sWM%20CONSTRUCTORES!5e0!3m2!1sen!2shn!4v1665779518437!5m2!1sen!2shn'
								referrerPolicy='no-referrer-when-downgrade'
								className='align-self-stretch radius-sm'
								style={{ border: '0', width: '100%', minHeight: '100%' }}
								allowFullScreen
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
