import { VideoPopup } from './video-popup';

// images
import bgImage from '../../../assets/images/team/team3.jpg';


export const ExpertsSection = () => {
	return (
		<>
			<section
				className='content-inner-4 overlay-black-middle'
				style={{
					backgroundImage: 'url(' + bgImage + ')',
					backgroundSize: 'cover',
					backgroundPosition: 'top',
				}}
			>
				<div className='container'>
					<div className='section-head style-1 w-75'>
						<h6 className='sub-title text-primary'>Nuestros expertos</h6>
						<h2 className='title m-b20 text-white'>
							Todos aman estar en un proyecto increíble. Conócenos.
						</h2>
					</div>
					<VideoPopup classChange='popup-youtube play-btn2 m-auto' />
				</div>
			</section>
		</>
	);
};
