// hooks
import { useMediaQuery } from '../../hooks/use-media-query';

// data
const companyValues = [
	'Confianza',
	'Transparencia',
	'Compromiso',
	'Responsabilidad',
	'Disciplina',
];

export const CertificateComponent = () => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<>
			<div
				className='section-head style-1 m-auto mb-4 mx-4 mx-md-4'
				style={{
					display: 'grid',
					gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
					gap: isMobile ? '1rem' : '3rem',
				}}
			>
				<div className='mx-md-5'>
					<h5 className='text-primary mb-4'>Política de calidad</h5>
					<p className='mb-4'>
						Somos una empresa lideres en la construcción de obras de
						infraestructura, comprometida con la satisfacción de nuestros
						clientes por medio del cumplimiento de sus requisitos, legales y
						reglamentarios aplicables, así como los compromisos adquiridos con
						las partes interesadas pertinentes.
					</p>
				</div>
				<div>
					<h5 className='text-primary mb-4'>Nuestros valores</h5>
					<p className='mb-4'>
						Para llevar a cabo nuestro trabajo y los proyectos de nuestros
						clientes, en William & Molina S. A. de C.V. nos identificamos en
						base a los siguientes valores:
					</p>
					<ul
						className='mt-2 mb-3'
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
						}}
					>
						{companyValues.map((value) => (
							<div className='d-flex gap-2' key={value}>
								<i className='flaticon-checked'></i>
								<li>{value}</li>
							</div>
						))}
					</ul>
				</div>
			</div>
			<section className='container'>
				<div className='shadow certificate-wrapper mx-auto'>
				<div className='text-wrapper'>
					<div>
						<h6>Certificación ISO 9001</h6>
						<p>
							Estamos certificados con ISO 9001 desde el año 2010, brindándole a
							nuestros clientes la confianza en los proyectos y servicios
							suministrados. Permitiéndonos ser competitivos con la entrega de
							proyectos o servicios de mayor calidad.
						</p>
					</div>
				</div>
				<div className='certificate-logos-wrapper'>
					<div>
						<img
							width={70}
							src='https://grupoplatino.blob.core.windows.net/grupo-platino/Sello-ICONTEC_ISO-9001.png'
							alt='Noma ISO 9001'
						/>
						<p className='iso-font-family'>SC-CER77176</p>
					</div>
					<div>
						<img
							width={110}
							src='https://grupoplatino.blob.core.windows.net/grupo-platino/Logo-IQNet.png'
							alt='Calidad William y Molina'
						/>
					</div>
				</div>
				</div>
			</section>
		</>
	);
};
