import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// hooks
import { useMediaQuery } from '../../../hooks/use-media-query';
import { useIntersectionObserver } from '../../../hooks/use-intersection-observer';

// Images
import about12 from '../../../assets/images/machinery/IMG_4211.jpg';
import about13 from '../../../assets/images/team/team8.jpg';
import { GlobalContext } from '../../../context/globalContext';

// data
const counterSection = [
	{ title: 'm² de construcción vertical', number: '150000' },
	{
		title: 'millones m² de urbanización',
		number: '1.5',
		decimal: true,
	},
	{ title: 'km de red vial', number: '500' },
	{
		title: 'metros lineales de sistemas de conducción de agua instalada',
		number: '50000',
	},
	{ title: 'metros lineales de construcción de puentes', number: '1500' },
	{ title: 'viviendas construidas', number: '2500' },
];

export const AboutUs = () => {
	const isMobile = useMediaQuery('(max-width: 767px)');
	const { companyInfo } = React.useContext(GlobalContext);

	/* A hook used to detect when the element is visible on the screen. */
	const imageRef = useRef(null);
	const imageEntry = useIntersectionObserver(imageRef, {
		freezeOnceVisible: true,
	});
	const imageIsVisible = !!imageEntry?.isIntersecting;

	const counterRef = useRef(null);
	const counterEntry = useIntersectionObserver(counterRef, {
		freezeOnceVisible: true,
	});
	const counterIsVisible = !!counterEntry?.isIntersecting;

	return (
		<section className='content-inner mx-md-5 mx-4'>
			<div className='mx-md-4'>
				<div className='row about-style7'>
					<div
						className='col-lg-6 m-b30 align-self-center aos-item'
						data-aos='fade-up'
						data-aos-duration='800'
						data-aos-delay='200'
					>
						<div className='about-content'>
							<div className='section-head style-1'>
								<h5 className='sub-title text-primary'>
									Bienvenido a Constructora William & Molina
								</h5>
								<span className='text-primary'>
									{isMobile ? <br /> : ' '}
									Desde 1992
								</span>
								<h2 className='title m-b20'>
									Más de 30 años de excelencia en la construcción
								</h2>
								<p>
									{companyInfo.aboutUs}
								</p>
							</div>
							<Link
								to={'./about-us'}
								className='btn btn-primary btn-border m-r10 m-b10'
							>
								VER MÁS
							</Link>
						</div>
					</div>
					<div className='col-lg-6 m-b30' ref={imageRef}>
						<div className='about-media'>
							<div className={`split-box ${imageIsVisible && 'split-active'}`}>
								<div>
									<img
										className='main-img aos-item'
										src={about12}
										alt='Volqueta SANY'
									/>
								</div>
							</div>
							<LazyLoadImage effect='opacity' delayTime={300} className='ov-img aos-item' src={about13} alt='Excavadora SANY' />
						</div>
					</div>
					<div className='gap-5 mt-5 justify-content-lg-center m-auto'
						style={{
							display: 'grid',
							gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr',
						}}
						ref={counterRef}>
						{/* Metrics */}
						{counterSection.map((data, ind) => (
							<div
								className='counter-style-1 aos-item d-flex flex-column align-items-center text-center'
								data-aos='fade-up'
								data-aos-duration='800'
								data-aos-delay='300'
								key={ind}
							>
								<div className='counter-num text-primary'>
									<small>+</small>
									<h2 className='counter text-primary'>
										{counterIsVisible && (
											<CountUp
												end={data.number}
												duration={3}
												decimals={data.decimal ? '1' : '0'}
												separator=','
												decimal='.'
											/>
										)}
									</h2>
								</div>
								<h5 className='counter-text m-auto'>{data.title}</h5>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};
