// hooks
import axios from 'axios';

import production from '../config';

/* Setting the base url for this api endpoint. */
const baseUrl = `${production.uriAPI}/Projects`;

// William & Molina company id
const COMPANY_ID = 3;

export const ProjectService = {
  getAllProjects: () => {
    return axios.get(`${baseUrl}/GetAll/${COMPANY_ID}`)
      .then(response => response.data)
      .catch(error => error);
  },

  getProjectById: (id) => {
    return axios.get(`${baseUrl}/GetById/${id}`)
      .then(response => response.data)
      .catch(error => error);
  }
}