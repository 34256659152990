import Lottie from 'react-lottie';

// images
// import lottieLoading from '../../assets/lotties/building-loading.json';
import lottieLoading from '../../assets/lotties/construction-loading.json';

// hooks
import { useMediaQuery } from '../../hooks/use-media-query';

/**
 * A modal with a loading animation and a loading text
 */
export const LoadingModal = () => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<div className='loading-modal'>
			<h4
				className='shimmer'
				data-text='Cargando...'
				style={{ fontSize: '46px' }}
			>
				Cargando
				<span className='loading-dots'>.</span>
				<span className='loading-dots'>.</span>
				<span className='loading-dots'>.</span>
			</h4>
			<Lottie
				options={{
					animationData: lottieLoading,
					loop: true,
					autoplay: true,
				}}
				style={{ display: 'grid', placeItems: 'center' }}
				width={isMobile ? '100%' : '40%'}
				height={'auto'}
			/>
		</div>
	);
};
