import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
//import { SRLWrapper } from "simple-react-lightbox";
import { Tab, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PageTitle } from '../../shared/page-title';
import image from '../../assets/images/logoColor.png';
// context
import { GlobalContext } from '../../context/globalContext';
import { Helmet } from 'react-helmet';

// WM Constructores Id
const COMPANY_ID = 3;

export function ProjectsPage() {
	const { projects } = React.useContext(GlobalContext);
	const allProjects = projects.filter(project => project.companyId === COMPANY_ID);
	const mappedProjects = [];
	allProjects.forEach((project) => {
		/* Mapping the projects by category. */
		const found = mappedProjects.find((p) => p.category === project.category.name);
		if (found) {
			found.projects.push(project);
		} else {
			mappedProjects.push({
				category: project.category.name,
				projects: [project],
			});
		}
	});

	return (
		<>
			<Helmet>
				<title>W&M - Proyectos</title>
				<meta name="description"
					content="Amplia experiencia en infraestructura vial, movimientos de tierra masivos, complejos de vivienda, complejos comerciales e industriales, obras hidráulicas y redes de agua potable, alcantarillado sanitario y pluvial, así como también con proyectos de construcción de edificaciones verticales y represas hidroeléctricas." />
				<meta name='keywords' content='San pedro sula, empresa constructora, infraestructura vial, complejos de vivienda,, obras hidráulicas, edificaciones verticales, represas hidroeléctricas' />
			</Helmet>
			<div className='page-content bg-white'>
				<PageTitle title='Proyectos' parentPage='Inicio' />
				{/* <!-- Projects --> */}
				<section className='content-inner-1 px-md-4'>
					<div className='container-fluid px-4 px-md-5'>
						<Tab.Container defaultActiveKey='All'>
							<div className='site-filters style-1 clearfix center mb-5'>
								<Nav as='ul' className='filters' data-toggle='buttons'>
									<Nav.Item as='li' className='btn'>
										<input type='radio' />
										<Nav.Link eventKey='All'>Mostrar todos</Nav.Link>
									</Nav.Item>
									{
										mappedProjects.map((project) => {
											return (
												<Nav.Item as='li' className='btn' key={project.category}>
													<input type='radio' />
													<Nav.Link eventKey={project.category}>{project.category}</Nav.Link>
												</Nav.Item>
											);
										})
									}
								</Nav>
							</div>
							<Tab.Content>
								<Tab.Pane eventKey='All'>
									<div className='clearfix'>
										<ResponsiveMasonry
											columnsCountBreakPoints={{ 350: 1, 580: 2 }}
										>
											<Masonry gutter='30px'>
												{allProjects.map((project, i) => (
													<div
														className='image-tooltip-effect dz-box style-2 rounded-2 shadow aos-item' data-aos='fade-up'
														data-aos-duration='800'
														data-aos-delay={`${i * 50}`}
														key={project.projectId}
													>
														<Link
															to={`./project-details/${project.projectId}`}
															className={`${i / 2 === 0 ? 'height-lg' : 'height-sm'}`}
														>
															<img src={project.images.length !== 0 ? project.images[0].url : image} alt={project.name} className='img-fluid' />
														</Link>
														<div className='m-t10 p-4'>
															<h4>{project.name}</h4>
															<h6 className='sub-title text-primary m-b0'>
																{project.category.name}
															</h6>
														</div>
													</div>
												))}
											</Masonry>
										</ResponsiveMasonry>
									</div>
								</Tab.Pane>
								{
									!allProjects.length > 0 && <h2 className='text-center'>No hay proyectos para mostrar</h2>
								}
								{
									mappedProjects.map((element, ind) => (
										<Tab.Pane eventKey={element.category} key={element.category + ind}>
											<div className='clearfix'>
												<ResponsiveMasonry
													columnsCountBreakPoints={{ 350: 1, 580: 2 }}
												>
													<Masonry gutter='30px'>
														{element.projects.map && element.projects.map((project, i) => (
															<div
																className='image-tooltip-effect dz-box style-2 rounded-2 shadow'
																key={project.projectId}
															>
																<Link
																	to={`./project-details/${project.projectId}`}
																	className={`${i / 2 === 0 ? 'height-lg' : 'height-sm'}`}
																>
																	<img src={project.images.length !== 0 ? project.images[0].url : image} alt={project.name} className='img-fluid' />
																</Link>
																<div className='m-t10 p-4'>
																	<h4>{project.name}</h4>
																	<h6 className='sub-title text-primary m-b0'>
																		{project.category.name}
																	</h6>
																</div>
															</div>
														))}
													</Masonry>
												</ResponsiveMasonry>
											</div>
										</Tab.Pane>
									))
								}
							</Tab.Content>
						</Tab.Container>
					</div>
				</section>
			</div>
		</>
	);
}
