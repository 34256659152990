import CountUp from 'react-countup';
import React from 'react';
import { Link } from 'react-router-dom';
import { PolicySlider } from './policy-slider';
import { GlobalContext } from '../../../context/globalContext';

export const MainAboutUsContent = () => {
	const { companyInfo } = React.useContext(GlobalContext);
	return (
		<>
			<div className='row'>
				<div
					className='col-lg-6 aos-item'
					data-aos='fade-up'
					data-aos-duration='800'
					data-aos-delay='200'
				>
					<div className='section-head style-1'>
						<h3 className='title m-b30'>Somos Siempre La Mejor Opción</h3>
						<div className='exp-row'>
							<h2 className='year counter' style={{color: `var(--secondary)`}}>
								{' '}
								<CountUp end={30} prefix='+' duration={4} />
							</h2>
							<p>
								AÑOS DE <span>EXPERIENCIA</span>
							</p>
						</div>
					</div>
				</div>
				<div
					className='col-lg-6 m-b30 aos-item'
					data-aos='fade-up'
					data-aos-duration='800'
					data-aos-delay='300'
				>
					<p className='m-b30'>
						{companyInfo.aboutUs}
					</p>
					<Link
						to={'./contact-us'}
						className='btn btn-primary btn-border btn-border m-r10 m-b10'
					>
						CONTÁCTENOS
					</Link>
				</div>
			</div>
			<PolicySlider />
		</>
	);
};
