import React from 'react';
import ReactDOM from "react-dom";
import App from './App';
import SimpleReactLightbox from 'simple-react-lightbox'
import reportWebVitals from './reportWebVitals';
import { GlobalProvider } from './context/globalContext';

const root = document.getElementById('root');

ReactDOM.render(
    <React.Fragment>
        <SimpleReactLightbox>
            <GlobalProvider>
                <App />
            </GlobalProvider>
        </SimpleReactLightbox>
    </React.Fragment>, root
);

//document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



