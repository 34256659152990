import { Route, Switch, HashRouter } from 'react-router-dom';
import ScrollToTop2 from 'react-scroll-to-top';

// pages
import { HomePage } from './Home/index';
import { ProjectsPage } from './Projects/index';
import { ProjectDetails } from './project-details/index';

import { ContactUsPage } from './contact-us/index';
import { NotFoundPage } from './not-found';

// components
import { Layout } from '../features/layout';
import { ScrollToTop } from '../features/scroll-to-top';
import { DarkTheme } from '../features/dark-theme';
import { AboutUsPage } from './about-us/index';

const Index = () => {
	return (
		<HashRouter basename='/'>
			<div className='page-wraper'>
				<Layout>
					<Switch>
						<Route path='/' exact component={HomePage} />
						<Route path='/about-us' exact component={AboutUsPage} />
						<Route path='/contact-us' exact component={ContactUsPage} />
						<Route path='/projects' exact component={ProjectsPage} />
						<Route path='/project-details/:id' exact component={ProjectDetails} />
						<Route path='*' exact component={NotFoundPage} />
					</Switch>
					<ScrollToTop />
					<ScrollToTop2
						top={100}
						className='fas fa-arrow-up scroltop'
						smooth
					/>
				</Layout>
			</div>
			<DarkTheme />
		</HashRouter>
	);
}
export default Index;
