import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';

// hooks
import { useLockBodyScroll } from '../../../hooks/use-lock-body-scroll';

import teamVideo from '../../../assets/videos/team_video.mp4';

export const VideoPopup = ({ classChange }) => {
	const [isOpen, setOpen] = useState(false);
	useLockBodyScroll(isOpen);

	return (
		<React.Fragment>
			<ModalVideo
				channel='custom'
				isOpen={isOpen}
				url={teamVideo}
				onClose={() => setOpen(false)}
				key={teamVideo}
			/>
			<Link to={'#'} className={classChange} onClick={() => setOpen(true)}>
				<i className='fa fa-play'></i>
			</Link>
		</React.Fragment>
	);
};

export const VideoPopup2 = () => {
	const [isOpen, setOpen] = useState(false);

	return (
		<React.Fragment>
			<ModalVideo
				channel='youtube'
				isOpen={isOpen}
				videoId='_FRZVScwggM'
				onClose={() => setOpen(false)}
			/>
			<Link
				to={'#'}
				className='popup-youtube play-btn3'
				onClick={() => setOpen(true)}
			>
				Play Video
			</Link>
		</React.Fragment>
	);
};
