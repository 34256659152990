import { Link } from 'react-router-dom';

import bnr1 from '../../assets/images/machinery/DSC_1974.jpg';

export const NotFoundPage = () => {
	return (
		<>
			<div
				className='error-page overlay-black-light'
				style={{
					backgroundImage: 'url(' + bnr1 + ')',
					backgroundPosition: 'center',
                    backgroundSize: 'cover',
				}}
			>
				<div className='error-inner text-center'>
					<div className='dz_error' data-text='404'>
						404
					</div>
					<h2 className='error-head'>
						Lo sentimos. Pero la pagina que estas buscando no existe
					</h2>
					<Link to='/' className='btn btn-primary btn-border white-border'>
						VOLVER AL INICIO
					</Link>
				</div>
			</div>
		</>
	);
};
