import bgImage from '../../assets/images/team/team4.jpg';

export const Career = () => {
	return (
		<section
			className='career-section overlay-black-dark'
			style={{ backgroundImage: `url(${bgImage})` }}
		>
			<div className='career-container container-fluid px-md-5 px-3'>
				<div className='px-md-4'>
					<div>
						<h3 className='title m-b20 px-md-5'>
							Conoce todas nuestras <br />
							<span>oportunidades laborales</span>
						</h3>
						<p className='text-light container-sm'>
							Desarrolla tu carrera con nosotros. Aplica a nuestras distintas
							oportunidades <br /> de empleo por medio de nuestro portal de
							empleos o por nuestra página de LinkedIn
						</p>
					</div>
					<div className='career-buttons'>
						<a
							className='btn btn-primary btn-border-white btn-border m-r10 m-b10'
							href='https://www.grupoplatino.hn/#/Careers'
							target='_blank'
							rel='noopener noreferrer'
						>
							Portal de empleos
						</a>
						<a
							className='btn btn-primary btn-border-white btn-border m-r10 m-b10'
							href='https://www.linkedin.com/company/grupo-empresarial-platino-hn/mycompany/'
							target='_blank'
							rel='noopener noreferrer'
						>
							LinkedIn
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};
