// components
import { ServicesGrid } from './services-grid';

// images
import pattern1 from '../../../assets/images/dotted.png';

export const OurServices = () => {
	return (
		<section className='px-md-5' style={{ backgroundImage: 'url(' + pattern1 + ')' }}>
			<div className='mx-3 mx-md-4'>
				<div className='section-head text-center style-1'>
					<h2
						className='title m-b20 aos-item'
						data-aos='fade-right'
						data-aos-duration='800'
						data-aos-delay='200'
					>
						Ofrecemos todos los servicios de ingeniería y construcción
					</h2>
					<p>
						WM Constructores es el pilar de un grupo empresarial conformado por
						seis empresas, las cuales tienen una participación de mercado muy
						importante y se perfilan hacia el liderazgo de cada uno de sus
						rubros gracias a la constante innovación y mejora continua de sus
						capacidades.
					</p>
				</div>
				<ServicesGrid />
			</div>
		</section>
	);
};
