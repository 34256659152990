// hooks
import axios from 'axios';

import production from '../config';

// William & Molina company id
const COMPANY_ID = 3;

/* Setting the base url for this api endpoint. */
const baseUrl = `${production.uriAPI}/Companies`;

export const CompaniesService = {
  getCompanyInfo: () => {
    return axios.get(`${baseUrl}/GetById/${COMPANY_ID}`)
      .then(response => response.data)
      .catch(error => error);
  },

  // rest of api calls
}