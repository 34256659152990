import React from 'react';
import { Link } from 'react-router-dom';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import { Autoplay, Parallax, Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/css';

//Images
import Slider2 from '../../../assets/images/hero.jpg';
import Slider3 from '../../../assets/images/hero2.jpg';
import Slider4 from '../../../assets/images/project/inter_norte2.JPG';

// hooks
import { useMediaQuery } from '../../../hooks/use-media-query';

const bannerBlog = [
	{
		image: Slider2,
		title: 'CONSTRUCCIÓN EN UN NUEVO NIVEL.',
		desc: "Amplia experiencia en infraestructura vial, movimientos de tierra masivos, complejos de vivienda, complejos comerciales e industriales, obras hidráulicas y redes de agua potable, alcantarillado sanitario y pluvial, así como también con proyectos de construcción de edificaciones verticales y represas hidroeléctricas."
	},
	{
		image: Slider3,
		title: 'CONFIANZA, RESPONSABILIDAD, DISCIPLINA.',
		desc: "Talento humano competente y comprometido que integra innovación al desarrollo de nuestros proyectos, buscando además mantenerse como empresa líder en el ramo de la construcción a través de un sistema de mejora continua."
	},
	{
		image: Slider4,
		title: 'CONSTRUYENDO CON TRANSPARENCIA Y COMPROMISO.',
		desc: "Comprometidos con la satisfacción de nuestros clientes por medio del cumplimiento de sus requisitos, legales y reglamentarios aplicables, así como los compromisos adquiridos con las partes interesadas pertinentes."
	},
];

export function HeroHome() {
	const navigationPrevRef = React.useRef(null);
	const navigationNextRef = React.useRef(null);

	// is mobile?
	const isMobile = useMediaQuery('(max-width: 767px)');
	return (
		<div className='main-slider2'>
			<Swiper
				className='swiper-container main-slider-2 banner-inner'
				speed={1500}
				parallax={true}
				loop={true}
				autoplay={{
					delay: 2800,
				}}
				pagination={{
					el: '.swiper-pagination-slider1',
					type: 'progressbar',
				}}
				slidesPerView={1}
				spaceBetween={0}
				onSwiper={(swiper) => {
					// Delay execution for the refs to be defined
					setTimeout(() => {
						// Override prevEl & nextEl now that refs are defined
						if (swiper.params?.navigation) {
							swiper.params.navigation.prevEl = navigationPrevRef?.current;
							swiper.params.navigation.nextEl = navigationNextRef?.current;

							// Re-init navigation
							swiper.navigation.destroy();
							swiper.navigation.init();
							swiper.navigation.update();
						}
					});
				}}
				modules={[Autoplay, Parallax, Navigation]}
			>
				{bannerBlog.map((data, ind) => (
					<SwiperSlide
						style={{
							backgroundImage: 'url(' + data.image + ')',
							backgroundSize: 'cover',
							backgroundPosition: isMobile ? 'center' : 'left',
						}}
						key={ind}
					>
						<div className='banner-content container-fluid mx-md-5 mx-1'>
							<div className='row'>
								<div className='col-lg-9'>
									<h6 className='display-3 text-white fw-bold' data-swiper-parallax='-500'>
										{data.title}
									</h6>
									<p data-swiper-parallax='-1000'>
										{data.desc}
									</p>
									<Link to={'./about-us'} className='btn btn-primary'>
										Quiero saber más{' '}
										<i className='fas fa-long-arrow-alt-right m-l15'></i>
									</Link>
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}

				<div className='swiper-button' style={{ zIndex: 1 }}>
					<div className='swiper-button-prev' ref={navigationPrevRef}>
						<i className='las la-angle-left'></i>
					</div>
					<div className='swiper-button-next' ref={navigationNextRef}>
						<i className='las la-angle-right'></i>
					</div>
				</div>
			</Swiper>
		</div>
	);
}
