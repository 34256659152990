import { useState, useEffect } from 'react';

/**
 * This function will run a callback function when the page loads
 */
export const useFirstPageLoad = () => {
	const [loadingPage, setLoadingPage] = useState(true);
	// This will run one time after the component mounts
	useEffect(() => {
		// callback function to call when event triggers
		const onPageLoad = () => {
			// this setTimeout is temporal, it's just to see the loading page
			setTimeout(() => {
				setLoadingPage(false);
			}, 3000);
		};

		// Check if the page has already loaded
		if (document.readyState === 'complete') {
			onPageLoad();
		} else {
			window.addEventListener('load', onPageLoad, false);
			// Remove the event listener when component unmounts
			return () => window.removeEventListener('load', onPageLoad);
		}
	}, []);

	return loadingPage;
};
