import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from '../../../assets/images/logoColor.png';
import logoWM from '../../../assets/images/logoWM.png';

// hooks
import { useOnClickOutside } from '../../../hooks/use-click-outside';

export const Header = () => {
	const ref = useRef();
	//Sidebar
	const [openToggleBtn, setOpenToggleBtn] = useState(false);

	// for sticky header
	const [headerFix2, setheaderFix2] = useState(false);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			setheaderFix2(window.scrollY > 50);
		});
	}, []);

	/* for open menu bar */
	const [sidebarOpen2] = useState(false);

	const handleSidaNavOpen = useCallback(() => setOpenToggleBtn(false), []);
	useOnClickOutside(ref, handleSidaNavOpen);

	useEffect(() => {
		var mainMenu = document.getElementById('OpenMenu2');
		if (mainMenu) {
			if (sidebarOpen2) {
				mainMenu.classList.add('show');
			} else {
				mainMenu.classList.remove('show');
			}
		}
	});

	return (
		<>
			<header className='site-header mo-left header header-transparent style-2' ref={ref}>
				<div
					className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix2 ? 'is-fixed' : ''
						}`}
				>
					<div className='main-bar clearfix '>
						<div className='container-fluid clearfix'>
							<div className='logo-header mostion logo-dark'>
								<Link to={'/'}>
									<LazyLoadImage effect='opacity' delayTime={300} src={logo} alt='WM Constructores' />
								</Link>
							</div>
							<div className='logo-header mostion logo-white'>
								{sidebarOpen2 === false ? (
									<Link to={'/'}>
										<LazyLoadImage effect='opacity' delayTime={300} src={logoWM} alt='WM Constructores' />
									</Link>
								) : (
									<Link to={'/'}>
										<img
											src={
												document.body.className.includes('dark') ? logoWM : logo
											}
											alt='WM Constructores logo'
										/>
									</Link>
								)}
							</div>
							<button
								className={`navbar-toggler navicon justify-content-end ${openToggleBtn ? 'open' : 'collapsed'
									}`}
								onClick={() => setOpenToggleBtn(!openToggleBtn)}
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#navbarNavDropdown'
								aria-controls='navbarNavDropdown'
								aria-expanded='false'
								aria-label='Toggle navigation'
							>
								<span></span>
								<span></span>
								<span></span>
							</button>
							<div
								id='navbarNavDropdown'
								className={`header-nav navbar-collapse justify-content-end ${openToggleBtn ? ' collapse show' : ' collapse'
									}`}
							>
								<div className='logo-header'>
									<Link to={'/'}>
										<LazyLoadImage src={logo} delayTime={300} alt='WM Constructores logo' />
									</Link>
								</div>
								<ul className='nav navbar-nav navbar navbar-left'>
									<li onClick={handleSidaNavOpen}>
										<Link to='/'>
											<span>INICIO</span>
										</Link>
									</li>
									<li onClick={handleSidaNavOpen}>
										<Link to='/projects'>
											<span>PROYECTOS</span>
										</Link>
									</li>
									<li onClick={handleSidaNavOpen}>
										<Link to='/about-us' >
											<span>ACERCA DE NOSOTROS</span>
										</Link>
									</li>
									<li onClick={handleSidaNavOpen}>
										<Link to='/contact-us'>
											<span>CONTACTO</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};
