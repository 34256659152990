import React, { useState } from 'react';
import { GlobalContext } from '../../context/globalContext';
//Components
import { PageTitle } from '../../shared/page-title';
import { Career } from '../../shared/career';
import axios from 'axios';
import production from '../../config/index';
//images
import heroImage from '../../assets/images/machinery/DJI_0231.JPG';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

export const ContactUsPage = () => {
	const headers = {
        'Content-Type': 'application/json',
    };
	const { companyInfo } = React.useContext(GlobalContext);
    const [message, setMessage] = useState({});
	const COMPANY_ID = 3;
	const sendEmail = (e) => {
		e.preventDefault();
		var data = {
            "name": message.fullName,
            "email": message.email,
            "phone": message.phoneNumber,
            "subject": message.subject,
            "message": message.message,
            "date": new Date(),
            "companyId": COMPANY_ID
        };

		axios.post(`${production.uriAPI}/Suggestions/Create`, data, { crossdomain: true }, { headers: headers })
            .then( () => {
                document.getElementById("contact-form").reset();
				Swal.fire('Mensaje enviado!', 'Tu mensaje fue enviado exitosamente', 'success');
            }).catch(() => {
				Swal.fire('Oppss! Ocurrio un error', 'Tu mensaje no pudo ser enviado debido a un problema en el servidor, por favor vuelve a intentarlo más tarde', 'error');
            });
	};

	return (
		<>
		<Helmet>
            <title>W&M - Contacto</title>
            <meta name="description"
			content="WM Constructores busca mejorar posicionamiento dentro del mercado Hondureño y poder extenderse regionalmente, para ello trabaja en elevar las competencias de su recurso humano en las áreas técnicas, financieras y administrativas, con el fin de obtener los procesos más eficientes del rubro." />
			<meta name='keywords' content='Construcción, san pedro sula, constructoras en honduras, obras de infraestructura, william, molina, wym'/>
        </Helmet>
			<div className='page-content bg-white'>
				<PageTitle title='Contáctenos' parentPage='Inicio' childPage='' heroImage={heroImage} />
				<section className='content-inner-2 pt-0'>
					<div className='map-iframe'>
						<iframe
							title='myFrame'
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3844.7277407054503!2d-87.98799288545983!3d15.499070089235323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f66449bbe9fe2c3%3A0x9e812d040648c6d2!2sWM%20CONSTRUCTORES!5e0!3m2!1sen!2shn!4v1665779518437!5m2!1sen!2shn'
							referrerPolicy='no-referrer-when-downgrade'
							className='align-self-stretch radius-sm'
							style={{ border: '0', width: '100%', minHeight: '100%' }}
							allowFullScreen
						/>
					</div>
				</section>
				<section
					className='contact-wraper1'
					style={{ backgroundColor: 'var(--primary)' }}
				>
					<div className='container-fluid'>
						<div className='row m-l5 aos-item' data-aos='fade-right'
							data-aos-duration='800'
							data-aos-delay='200'>
							<div className='col-lg-5'>
								<div className='contact-info'>
									<div className='section-head text-white style-1'>
										<h3 className='title text-white'>Contáctenos</h3>
										<p>
											Si está interesado en nuestros servicios, por favor llene el formulario y nos pondremos en contacto con usted.
										</p>
									</div>
									<ul className='no-margin'>
										<li className='icon-bx-wraper text-white left m-b30'>
											<div className='icon-md'>
												<span className='icon-cell'>
													<i className='flaticon-placeholder-1'></i>
												</span>
											</div>
											<div className='icon-content'>
												<h4 className=' dz-tilte text-white'>Estamos ubicados en</h4>
												<p className='font-18'>
													{companyInfo.address}
												</p>
											</div>
										</li>
										<li className='icon-bx-wraper text-white left m-b30'>
											<div className='icon-md'>
												<span className='icon-cell'>
													<i className='flaticon-chat'></i>
												</span>
											</div>
											<div className='icon-content'>
												<h4 className='dz-tilte text-white'>Nuestro contacto</h4>
												<p className='font-18'>
													<a href={`mailto:${companyInfo.email}`} className='text-white d-flex align-items-center gap-3'>
														<i className='flaticon-message'></i>
														<span>{companyInfo.email}</span>
													</a>
													<a href={`tel:+504 ${companyInfo.cellPhone}`} className='text-white d-flex align-items-center gap-3'>
														<i className='flaticon-call'></i>{' '}
														<span>+504 {companyInfo.cellPhone}</span>
													</a>
												</p>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div className='col-lg-7 m-b40 aos-item' data-aos="fade-up" data-aos-duration='800'
								data-aos-delay='200'>
								<div className='contact-area1 m-r20 m-md-r0'>
									<div className='section-head style-1'>
										<h6 className='sub-title text-primary'>CONTÁCTENOS</h6>
										<h3 className='title m-b20'>Póngase en contacto con nosotros</h3>
									</div>
									<form
									id='contact-form'
										className='dz-form dzForm'
										onSubmit={sendEmail}
									>
										<div className='input-group'>
											<input
												required
												onChange={e => setMessage({ ...message, fullName: e.target.value })}
												type='text'
												className='form-control'
												name='fullName'
												placeholder='Nombre completo'
											/>
										</div>
										<div className='input-group'>
											<input
												required
												onChange={e => setMessage({ ...message, email: e.target.value })}
												type='email'
												className='form-control'
												name='email'
												placeholder='Dirección de correo electrónico'
											/>
										</div>
										<div className='input-group'>
											<input
												required
												onChange={e => setMessage({ ...message, phoneNumber: e.target.value })}
												type='text'
												className='form-control'
												name='phoneNumber'
												pattern="[0-9]{4}-[0-9]{4}"
												placeholder='Número de teléfono (xxxx-xxxx)'
											/>
										</div>
										<div className='input-group'>
											<input
												required
												onChange={e => setMessage({ ...message, subject: e.target.value })}
												type='text'
												className='form-control'
												name='subject'
												placeholder='Titulo'
											/>
										</div>
										<div className='input-group'>
											<textarea
												required
												maxLength={250}
												onChange={e => setMessage({ ...message, message: e.target.value })}
												name='message'
												rows='5'
												className='form-control'
												placeholder='Mensaje'
											/>
										</div>
										<div>
											<button
												name='submit'
												type='submit'
												value='submit'
												className='btn w-100 btn-primary btn-border mb-5'
											>
												ENVIAR
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div>
					<Career />
				</div>
			</div>
		</>
	);
}
